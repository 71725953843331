import { Component } from '@angular/core';
import { RouterOutlet, RouterLink } from '@angular/router';
import { HeaderComponent } from '@pages/header/header.component';
import { AsyncPipe } from '@angular/common';
import { AuthService } from '@shared/auth/auth.service';
import { IdleTimeoutModalComponent } from '@components/idle-timeout-modal/idle-timeout-modal.component';
import { TimeoutWarningModalComponent } from '@components/timeout-warning-modal/timeout-warning-modal.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    HeaderComponent,
    AsyncPipe,
    IdleTimeoutModalComponent,
    TimeoutWarningModalComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'ccnx-console-ui';
  constructor(public authService: AuthService) {}
}
