@if(showTimeoutModal()) {
<div class="modal-overlay">
  <div class="modal-content">
    <div class="modal-header">
      <div class="header-title">
        <span i18n>Session Expired</span>
      </div>
    </div>
    <p i18n>Your session has expired due to inactivity</p>
  </div>
</div>
}
