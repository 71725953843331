<div
  [ngClass]="{
    'card': true,
  }"
>
  <app-logo-container [src]="src" />
      @if (subHeading) {
        <h2 class="sub-heading">{{ subHeading }}</h2>
      }
  <ng-content></ng-content>
</div>
