@if(showWarningModal()) {
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header">
        <div class="header-title">
          <span i18n>Session Expiration Warning</span>
        </div>
      </div>
      <p i18n>Your session will expire in {{ formatTimeRemaining() }} due to inactivity.</p>
      <div class="button-container">
        <app-button
          [label]="'Keep me signed in'"
          [type]="'button'"
          [className]="'primary-button'"
          (click)="continueSession()"
        />
        <app-button
          [label]="'Sign out now'"
          [type]="'button'"
          [className]="'primary-button'"
          (click)="redirectToSignOut()"
        />
      </div>
    </div>
  </div>
}
