import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  standalone: true,
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() public label?: string;
  @Input() public disable = false;
  @Input('aria-label') ariaLabel?: string;
  @Input() type: 'submit' | 'button' = 'button';
  @Input() className: 'primary-button' | 'primary-form-button' = 'primary-button';
}
