import {Component, Input} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-logo-container',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './logo-container.component.html',
  styleUrl: './logo-container.component.scss',
})
export class LogoContainerComponent {
  @Input('src') src: string = '../../../assets/images/netsmart-logo.png';
}
