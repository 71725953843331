import { Component, inject, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCircleUser,
  faCaretUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'shared/auth/auth.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, NgOptimizedImage],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private authService = inject(AuthService);
  faCircleUser = faCircleUser;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  username: string = '';
  isDropdownOpen = false;

  async ngOnInit() {
    const user = await this.authService.getUser();
    this.username = user?.name || user?.email || 'User';
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  async onItemSelect(value: string) {
    this.isDropdownOpen = false;
    if (value === 'settings') {
      window.open(
        'https://ntstcareconnect.okta.com/enduser/settings',
        '_blank',
      );
    } else if (value === 'signOut') {
      await this.authService.signOut();
    }
  }
}
