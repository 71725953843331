import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { LogoContainerComponent } from '@components/logo-container/logo-container.component';

@Component({
  selector: 'app-card',
  standalone: true,
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
  imports: [NgClass, LogoContainerComponent],
})
export class CardComponent {
  @Input('src') src: string = '../../../assets/images/netsmart-logo.png';
  @Input() subHeading: string = '';
}
