<div class="logout-container">
  <div class="card">
  <app-card>
    <div class="card-container">
      <h3 i18n>You have been successfully signed out, click the button below to sign in again:</h3>
      <app-button
        className="primary-form-button"
        [type]="'button'"
        [label]="'Sign in'"
        (click)="this.authService.signIn()"
      />
    </div>
  </app-card>
  </div>
</div>
