import { Component, inject } from '@angular/core';
import { AuthService } from '@shared/auth/auth.service';
import { ButtonComponent } from '@components/button/button.component';
import { CardComponent } from '@components/card/card.component';

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [ButtonComponent, CardComponent],
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss',
})
export class LogoutComponent {
  protected authService = inject(AuthService);
}
