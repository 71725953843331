import { Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { LogoutComponent } from '@pages/logout/logout.component';

export const routes: Routes = [
  {
    path: 'oidc/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'loggedout',
    component: LogoutComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: 'protected',
    pathMatch: 'full',
  },
  {
    path: 'protected',
    loadChildren: () =>
      import('./protected.routes').then((m) => m.PROTECTED_ROUTES),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired: (oktaAuth: any) => {
        oktaAuth.signInWithRedirect();
      },
    },
  },
  //TODO: Create a 404 page not found component
  {
    path: '**',
    redirectTo: 'protected',
  },
];
