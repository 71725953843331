<div class="header-container">
  <div class="header-image-container">
    <img
      ngSrc="./assets/images/logo-netsmart-color.svg"
      height="31"
      width="200"
      class="header-image"
      alt="Netsmart Logo"
      i18n-alt
      priority
    />
  </div>

  <div class="user-details">
    <fa-icon [icon]="faCircleUser" [size]="'xl'"/>
    <div class="dropdown">
      <button class="dropdown-toggle" (click)="toggleDropdown()">
        <option i18n>{{ username }}</option>
        <fa-icon
          [icon]="isDropdownOpen ? faCaretUp : faCaretDown"
          class="caret-icon"
          aria-label="caret-icon"
        />
      </button>
      <div class="dropdown-menu" [class.show]="isDropdownOpen">
        <a>
          <div class="dropdown-item" (click)="onItemSelect('settings')" i18n>Okta Settings</div>
        </a>
        <a>
          <div class="dropdown-item" (click)="onItemSelect('signOut')" i18n>Sign out</div>
        </a>
      </div>
    </div>
  </div>
</div>
