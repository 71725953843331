import { Component, inject } from '@angular/core';
import { IdleTimeoutService } from '@shared/services/idle-timeout/idle-timeout.service';
import { ButtonComponent } from '@components/button/button.component';

@Component({
  selector: 'app-timeout-warning-modal',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './timeout-warning-modal.component.html',
  styleUrl: './timeout-warning-modal.component.scss',
})
export class TimeoutWarningModalComponent {
  private _idleTimeoutService = inject(IdleTimeoutService);
  showWarningModal = this._idleTimeoutService.showWarning;
  timeRemaining = this._idleTimeoutService.timeRemaining;

  continueSession() {
    this._idleTimeoutService.continueSession();
  }

  async redirectToSignOut() {
    await this._idleTimeoutService.redirectToSignOut();
  }

  formatTimeRemaining(): string {
    const seconds = this.timeRemaining();
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes > 0) {
      return $localize`${minutes} minute${minutes > 1 ? 's' : ''} and ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
    }
    return $localize`${seconds} second${seconds !== 1 ? 's' : ''}`;
  }
}
