import { Component, inject } from '@angular/core';
import { IdleTimeoutService } from '@shared/services/idle-timeout/idle-timeout.service';
import { ButtonComponent } from '@components/button/button.component';
@Component({
  selector: 'app-idle-timeout-modal',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './idle-timeout-modal.component.html',
  styleUrl: './idle-timeout-modal.component.scss',
})
export class IdleTimeoutModalComponent {
  private _idleTimeoutService = inject(IdleTimeoutService);
  showTimeoutModal = this._idleTimeoutService.sessionExpired;
}
