import { inject, Injectable, signal } from '@angular/core';
import { AuthService } from '@shared/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class IdleTimeoutService {
  private readonly IDLE_TIMEOUT = 30 * 60; //Timeout after 30 minutes
  private readonly WARNING_TIMEOUT = 25 * 60; //Show warning with 5 minutes left (after 25 minutes)
  private warningTimeoutId: number | null = null;
  private countdownIntervalId: number | null = null;
  private logoutTimeoutId: number | null = null;

  sessionExpired = signal(false);
  showWarning = signal(false);
  timeRemaining = signal(this.IDLE_TIMEOUT - this.WARNING_TIMEOUT);
  private authService = inject(AuthService);

  constructor() {
    this.initListener();
    this.startTimer();
  }

  private initListener() {
    const events = [
      'mousedown',
      'mousemove',
      'keypress',
      'scroll',
      'touchstart',
      'click',
    ];

    const resetHandler = () => {
      if (!this.showWarning() && !this.sessionExpired()) {
        this.resetTimer();
      }
    };

    events.forEach((event) => {
      document.addEventListener(event, resetHandler, {
        passive: true,
      });
    });
  }

  private resetTimer() {
    if (this.sessionExpired()) {
      return;
    }
    this.clearTimeouts();
    this.startTimer();
  }

  private clearTimeouts() {
    if (this.warningTimeoutId) {
      window.clearTimeout(this.warningTimeoutId);
      this.warningTimeoutId = null;
    }
    if (this.countdownIntervalId) {
      window.clearInterval(this.countdownIntervalId);
      this.countdownIntervalId = null;
    }
    if (this.logoutTimeoutId) {
      window.clearTimeout(this.logoutTimeoutId);
      this.logoutTimeoutId = null;
    }
  }

  private startTimer() {
    if (this.sessionExpired()) {
      return;
    }

    this.timeRemaining.set(this.IDLE_TIMEOUT - this.WARNING_TIMEOUT);
    this.showWarning.set(false);

    this.warningTimeoutId = window.setTimeout(() => {
      this.showWarning.set(true);
      this.timeRemaining.set(this.IDLE_TIMEOUT - this.WARNING_TIMEOUT);

      this.logoutTimeoutId = window.setTimeout(
        async () => {
          this.handleSessionExpired();
          await this.authService.signOut();
        },
        (this.IDLE_TIMEOUT - this.WARNING_TIMEOUT) * 1000,
      );

      this.countdownIntervalId = window.setInterval(() => {
        const currentTime = this.timeRemaining();
        if (currentTime > 0) {
          this.timeRemaining.set(currentTime - 1);
        }
      }, 1000);
    }, this.WARNING_TIMEOUT * 1000);
  }

  private handleSessionExpired() {
    this.clearTimeouts();
    this.showWarning.set(false);
    this.sessionExpired.set(true);
  }

  continueSession() {
    if (this.sessionExpired()) {
      return;
    }
    this.showWarning.set(false);
    this.resetTimer();
  }

  async redirectToSignOut() {
    this.clearTimeouts();
    this.sessionExpired.set(false);
    this.showWarning.set(false);
    await this.authService.signOut();
  }
}
